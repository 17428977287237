<template>
  <div v-if="$hasFonction(user, 'Activer sociétés')">
    <b-card title="Paramètre de la société" class="mt-1">
      <b-card-text class="mb-2">
        Vous pouvez utiliser le formulaire ci-dessous pour mettre à jour les
        informations de la société.
      </b-card-text>

      <h5>Logo entreprise</h5>
      <b-img-lazy
          v-bind="mainProps"
          :src="src"
          alt="Image"
          class="border"
      ></b-img-lazy>

      <!-- form start -->
      <validation-observer ref="addForm">
        <b-form class="mt-2" @submit.prevent>
          <b-row>
            <b-form-group class="mr-2 col">
              <validation-provider #default="{ errors }" name="logo">
                <label for="logo">Image</label>
                <b-form-file
                    id="logo"
                    v-model="defaultForm.logo"
                    placeholder="Selectionner un logo"
                    accept=".jpg, .png, .gif"
                    @click="resetInputLogo"
                    @change="isOperatingEtat()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsLogo }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="col">
              <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
              >
                <label for="name"
                >Nom de la société <span style="color: red">*</span></label
                >
                <b-form-input
                    id="name"
                    placeholder="Nom"
                    v-model="defaultForm.name"
                    :state="errors.length > 0 ? false : null"
                    @click="resetInputName"
                    @change="isOperatingEtat()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsName }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>

          <b-row>
            <b-form-group class="mr-2 col">
              <validation-provider
                  #default="{ errors }"
                  name="Ifu"
                  rules="required"
              >
                <label for="ifu"
                >Ifu de la société <span style="color: red">*</span></label
                >
                <b-form-input
                    id="ifu"
                    placeholder="Ifu"
                    v-model="defaultForm.ifu"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    @click="resetInputIfu"
                    @change="isOperatingEtat()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsIfu }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="col">
              <validation-provider
                  #default="{ errors }"
                  name="rccm"
                  rules="required"
              >
                <label for="rccm"
                >Rccm de la société <span style="color: red">*</span></label
                >
                <b-form-input
                    id="rccm"
                    v-model="defaultForm.rccm"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="rccm"
                    @click="resetInputRccm"
                    @change="isOperatingEtat()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsRccm }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>

          <b-row>
            <b-form-group class="mr-2 col">
              <validation-provider
                  #default="{ errors }"
                  name="telephone"
                  rules="required"
              >
                <label for="telephone"
                >Téléphone de la société
                  <span style="color: red">*</span></label
                >
                <b-form-input
                    id="telephone"
                    v-model="defaultForm.telephone"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="Téléphone"
                    @click="resetInputTelephone"
                    @change="isOperatingEtat()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsTelephone }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="col">
              <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
              >
                <label for="email"
                >Email de la socété <span style="color: red">*</span></label
                >
                <b-form-input
                    id="email"
                    v-model="defaultForm.email"
                    :state="errors.length > 0 ? false : null"
                    type="email"
                    placeholder="Email"
                    @click="resetInputEmail"
                    @change="isOperatingEtat()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsEmail }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>

          <b-row>
            <b-form-group class="mr-2 col">
              <validation-provider
                  #default="{ errors }"
                  name="parc"
                  rules="required"
              >
                <label for="parc"
                >Parc de la société <span style="color: red">*</span></label
                >
                <b-form-input
                    id="parc"
                    v-model="defaultForm.parc"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="parc"
                    @click="resetInputParc"
                    @change="isOperatingEtat()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsParc }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="col">
              <validation-provider
                  #default="{ errors }"
                  name="paillote"
                  rules="required"
              >
                <label for="paillote"
                >Paillote de la socété
                  <span style="color: red">*</span></label
                >
                <b-form-input
                    id="paillote"
                    v-model="defaultForm.paillote"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="paillote"
                    @click="resetInputPaillote"
                    @change="isOperatingEtat()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsPaillote }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>

          <b-row>
            <b-form-group class="mr-2 col">
              <validation-provider
                  #default="{ errors }"
                  name="Responsable"
                  rules="required"
              >
                <label for="responsable"
                >Responsable de la société
                  <span style="color: red">*</span></label
                >
                <b-form-input
                    id="responsable"
                    placeholder="Responsable"
                    v-model="defaultForm.responsable"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    @click="resetInputResponsable"
                    @change="isOperatingEtat()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsResponsable }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="col">
              <validation-provider
                  #default="{ errors }"
                  name="address"
                  rules="required"
              >
                <label for="address"
                >Adresse de la socété <span style="color: red">*</span></label
                >
                <b-form-input
                    id="address"
                    v-model="defaultForm.address"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="addresse"
                    @click="resetInputAddress"
                    @change="isOperatingEtat()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsAddress }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>
          <b-row>
            <b-form-group class="col-4">
              <validation-provider #default="{ errors }" name="address">
                <label for="address">Caisse société (Imprimer carte importanteur) </label>
                <b-form-checkbox
                    v-model="defaultForm.impression_import"
                    name="check-button"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    style="
						padding-top: 4px;
						margin-left: 5px;
						margin-top: 2px;
						margin-bottom: 30px;
						"
                    @change="isOperatingEtat()"
                >
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsAddress }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group class="col-4">
              <validation-provider #default="{ errors }" name="address">
                <label for="address">Caisse association (Payer carte importateur)</label>
                <b-form-checkbox
                    v-model="defaultForm.paid_import"
                    name="check-button"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    style="
						padding-top: 4px;
						margin-left: 5px;
						margin-top: 2px;
						margin-bottom: 30px;
						"
                    @change="isOperatingEtat()"
                >
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsAddress }}</small>
              </validation-provider>

            </b-form-group>
            <b-form-group class="col-4">
              <validation-provider #default="{ errors }" name="address">
                <label for="address">Cette société est importatrice ?</label>
                <b-form-checkbox
                    v-model="defaultForm.importateur"
                    name="check-button"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    style="
						padding-top: 4px;
						margin-left: 5px;
						margin-top: 2px;
						margin-bottom: 30px;
						"
                    @change="isOperatingEtat()"
                >
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsAddress }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>
            <b-form-group >
                <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        name="association"
                    >
                        <label for="libelle">Selectionné le compte de paiement de salaire</label>
                        <b-form-select
                            v v-model="defaultForm.paid_salaire"
                            option-text="name"
                            option-value="value"
                            @input="checkAssociation()"
                            :options="associationsWithCaisse"
                            value-field="value"
                            class="col-lg-8 mt-8 mt-xl-1 ml-1"
                        >
                        </b-form-select>
                    </validation-provider>
                </b-form-group>
            </b-form-group>
          <b-row v-if="defaultForm.importateur == true">
            <b-form-group class="col mr-5">
              <validation-provider #default="{ errors }" name="logo">
                <label for="address">Image carte importateur</label>
                <b-form-file
                    id="carte"
                    v-model="defaultForm.imgCarte"
                    placeholder="Selectionner une image"
                    accept=".jpg, .png, .gif"
                    @click="resetInputCarte"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{ errorsCarte }}</small>
              </validation-provider>
            </b-form-group>

            <div class="col text-center">
              <b-img
                  v-bind="mainProps"
                  :src="defaultForm.carte"
                  alt="Image"
              ></b-img>
            </div>
          </b-row>

          <!-- submit button start -->
          <div class="mt-2">
            <b-button
                class="mr-2"
                variant="outline-secondary"
                type="reset"
                @click="resetInput"
            >
              Annuler
            </b-button>

            <b-button
                v-if="$hasFonction(user, 'Mettre à jour')"
                :disabled="isOperating"
                variant="primary"
                type="submit"
                @click.prevent="save"
            >
              Enregistrer
            </b-button>
          </div>
          <!-- submit button end -->
        </b-form>
      </validation-observer>
      <!-- form end -->
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
    BFormFile,
    BCard,
    BTable,
    BCardText,
    BCardTitle,
    BAlert,
    BModal,
    BPagination,
    BButton,
    BIconPencilFill,
    BIconTrashFill,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BImgLazy,
    BImg,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox, BFormSelectOption,
} from "bootstrap-vue";

export default {
  components: {
      BFormSelectOption,
    BCard,
    BTable,
    BModal,
    BCardText,
    BCardTitle,
    BAlert,
    BCardCode,
    BPagination,
    BButton,
    BIconPencilFill,
    BIconTrashFill,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BImgLazy,
    BImg,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BAvatar,
    BFormCheckbox,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      mainProps: {
        width: 120,
        height: 120,
        // class: 'my-5'
      },
      src: require("@/assets/images/logo/logo-dark2.png"),
      isOperating: true,
      required,
      integer,
      alpha,
      alphaDash,
      errorsCarte: "",
      nameState: null,
      saveSociete: false,
      // perPage:5,
      // currentPage: 1,
      // pageOptions: [5, 10, 25, 50, 100, { value: 1000000, text: "Tout" }],
      // ExcelSociete: null,
      // filter: null,
      // isVisible: false,
      // headers: [
      // 	{
      // 		key: 'logo',
      // 		label: 'Logo',
      // 		sortable: true,
      // 	},
      // 	{
      // 		key: 'name',
      // 		label: 'Nom société',
      // 		sortable: true,
      // 	},
      // 	{
      // 		key: 'responsable',
      // 		label: 'Responsable',
      // 		sortable: true,
      // 	},
      // 	{
      // 		key: 'id_juridique',
      // 		label: 'Id.Juridique',
      // 		sortable: true,
      // 	},
      // 	{
      // 		key: 'contacts',
      // 		label: 'contacts',
      // 		sortable: true,
      // 	},
      // 	{
      // 		key: 'address',
      // 		label: 'Adresse',
      // 		sortable: true,
      // 	},
      // 	{
      // 		key: 'actions',
      // 		label: 'Actions',
      // 		sortable: false,
      // 		class: 'w-25',
      // 	},
      // ],
      // societes: [],
      defaultForm: {
        id: 1,
      },
      errorsLogo: null,
      errorsName: null,
      errorsIfu: null,
      errorsRccm: null,
      errorsTelephone: null,
      errorsEmail: null,
      errorsParc: null,
      errorsPaillote: null,
      errorsResponsable: null,
      isOperating: false,
      errorsAddress: null,
      societes: [],
     associations: [],
     selectedAssociation: null,
    associationsWithCaisse: [],

      // panel: true,
      // collapseVisible: false,
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),

    baseurl() {
      return process.env.VUE_APP_baseURL;
    },
  },
  created() {
    this.getSocietes();
      this.getAssociations();
  },
  methods: {
    resetInput() {
      this.resetInputName();
      this.resetInputIfu();
      this.resetInputRccm();
      this.resetInputTelephone();
      this.resetInputEmail();
      this.resetInputParc();
      this.resetInputPaillote();
      this.resetInputResponsable();
      this.resetInputAddress();
      this.resetInputCarte();
    },
    resetInputLogo() {
      if (this.errorsLogo) {
        this.errorsLogo = null;
      }
    },
    resetInputCarte() {
      if (this.errorsCarte) {
        this.errorsCarte = null;
      }
    },
    resetInputName() {
      if (this.errorsName) {
        this.errorsName = null;
      }
    },

    resetInputIfu() {
      if (this.errorsIfu) {
        this.errorsIfu = null;
      }
    },
    resetInputRccm() {
      if (this.errorsRccm) {
        this.errorsRccm = null;
      }
    },

    resetInputTelephone() {
      if (this.errorsTelephone) {
        this.errorsTelephone = null;
      }
    },

    resetInputEmail() {
      if (this.errorsEmail) {
        this.errorsEmail = null;
      }
    },

    resetInputParc() {
      if (this.errorsParc) {
        this.errorsParc = null;
      }
    },

    resetInputPaillote() {
      if (this.errorsPaillote) {
        this.errorsPaillote = null;
      }
    },

    resetInputResponsable() {
      if (this.errorsResponsable) {
        this.errorsResponsable = null;
      }
    },

    resetInputAddress() {
      if (this.errorsAddress) {
        this.errorsAddress = null;
      }
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    isOperatingEtat() {
      this.isOperating = false;
    },
      getAssociations() {
          this.$http.get("/associations").then((response) => {
              var obj = {};
              this.associations = response.data.data;

              //  if (this.associationsList != null && this.associationsList.length===1) {
              /* console.log( this.associations[0].id)
                 this.selectedAssociation = this.associations[0].name;
                 this.checkAssociation();*/
              /* this.AssociationComptesAssocies = this.accountsList.filter(
                   (compte) =>{
                     console.log("comte",compte)
                     if (compte.author_type == "associe" && compte.author_association === 1 )
                     {
                       return this.transfertForm.compte_type=compte.id;
                     }
                   }
               )*/


              //}
              this.associationsWithCaisse = [];
              this.associations.forEach((element) => {
                  element.text = element.name;
              });
              this.associationsWithCaisse.push({
                  value: 0,
                  text: "Caisse",
                  name: "Caisse",
              });
              this.associations.forEach((element) => {
                  if (element.name) {
                      obj = {
                          value: element.id,
                          text: element.name,
                          name: element.name,
                      };
                      this.associationsWithCaisse.push(obj);
                  }
              });

          });
      },
      checkAssociation() {
          let selectedAssociation = this.associations.find(
              ({ name }) => name === this.defaultForm.paid_salaire
          );
      },


      getSocietes() {
      this.$http
          .get("/societes")
          .then((res) => {
            this.societes = res.data.data;
            localStorage.setItem(
                "societyAsImportateur",
                this.societes[0].importateur
            );
            if (this.societes.length) {
              this.saveSociete = false;
              this.src = this.societes[0].logo;
              this.defaultForm = {
                id: this.societes[0].id,
                name: this.societes[0].name,
                ifu: this.societes[0].ifu,
                rccm: this.societes[0].rccm,
                telephone: this.societes[0].telephone,
                email: this.societes[0].email,
                parc: this.societes[0].parc,
                paillote: this.societes[0].paillote,
                responsable: this.societes[0].responsable,
                address: this.societes[0].address,
                importateur: this.societes[0].importateur,
                impression_import: this.societes[0].impression_import,
                paid_import: this.societes[0].paid_import,
                carte: this.societes[0].carte,
                paid_salaire: this.societes[0].paid_salaire
              };
            } else {
              this.saveSociete = true;
              this.defaultForm = {};
            }
          })
          .catch((e) => {
            console.log("erreur de récupération:" + e.response.data.message);
          });
    },

    save() {
      if (parseInt(this.defaultForm.importateur)) {
        // console.log(this.defaultForm.importateur);
        this.addSociete();
      } else {
        this.$bvModal
            .msgBoxConfirm(
                "Souhaitez vous la création de la caisse de cette société ?",
                {
                  title: "Création de caisse",
                  okTitle: "oui",
                  cancelTitle: "non",
                  centered: true,
                  hideHeaderClose: false,
                  cancelVariant: "outline-secondary",
                }
            )
            .then((value) => {
              if (value === true) {
                this.defaultForm.createCompte = true;
              } else {
                this.defaultForm.createCompte = false;
              }
              this.addSociete();
            });
      }
    },

    addSociete() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.isOperating = true;
          const formData = new FormData();
          if (this.defaultForm.logo || this.defaultForm.imgCarte) {
            formData.append("logo", this.defaultForm.logo);
            formData.append("name", this.defaultForm.name);
            formData.append("ifu", this.defaultForm.ifu);
            formData.append("rccm", this.defaultForm.rccm);
            formData.append("telephone", this.defaultForm.telephone);
            formData.append("email", this.defaultForm.email);
            formData.append("address", this.defaultForm.address);
            formData.append("responsable", this.defaultForm.responsable);
            formData.append("parc", this.defaultForm.parc);
            formData.append("paillote", this.defaultForm.paillote);
            formData.append("importateur", this.defaultForm.importateur);
            formData.append("paid_import", this.defaultForm.paid_import);
            formData.append("impression_import", this.defaultForm.impression_import);
            formData.append("imgCarte", this.defaultForm.imgCarte);
            formData.append("paid_salaire", this.defaultForm.paid_salaire);

            if (this.defaultForm.createCompte) {
              formData.append("createCompte", this.defaultForm.createCompte);
            }
          }

          if (this.defaultForm && !this.saveSociete) {
            if (this.defaultForm.logo || this.defaultForm.imgCarte) {
              formData.append("id", this.defaultForm.id);
              formData.append("_method", "put");
              this.$http
                  .post("/societes/" + this.defaultForm.id, formData)
                  .then((result) => {
                    this.isOperating = false;
                    if (result.data.success) {
                      // this.resetInput();
                      /* this.defaultForm = JSON.parse(
                        JSON.stringify({ type: Object, default: () => {} })
                      ); */
                      this.showToast("Société ajouté avec succès", "success");
                      localStorage.setItem("societyAsImportateur",this.defaultForm.importateur);
                      location.reload()
                      //this.getSocietes();
                    } else {
                      this.showToast(result.data.message, "danger");
                    }
                  })
                  .catch((e) => {
                    this.isOperating = false;
                    var err = e.response.data.errors;
                    if (e.response.data.imgCarte) {
                      this.errorsCarte = (e.response.data.imgCarte[0]?e.response.data.imgCarte[0]+',':'') + (e.response.data.imgCarte[1]?e.response.data.imgCarte[1]+'.':'.');
                    }
                    if(err){
                      if (err.name) {
                        this.errorsName = err.name[0];
                      }
                      if (err.telephone) {
                        this.errorsTelephone = err.telephone[0];
                      }
                      if (err.email) {
                        this.errorsEmail = err.email[0];
                      }
                      if (err.ifu) {
                        this.errorsIfu = err.ifu[0];
                      }
                      if (err.rccm) {
                        this.errorsRccm = err.rccm[0];
                      }
                      if (err.responsable) {
                        this.errorsResponsable = err.responsable[0];
                      }
                    }


                  });
            } else {
                console.log(this.defaultForm)
              this.$http
                  .put("/societes/" + this.defaultForm.id, this.defaultForm)
                  .then((result) => {
                    this.isOperating = false;
                    if (result.data.success) {
                      //this.resetInput();
                      /* this.defaultForm = JSON.parse(
                        JSON.stringify({ type: Object, default: () => {} })
                      ); */
                      this.showToast("Société ajouté avec succès", "success");
                      //this.getSocietes()
                      localStorage.setItem("societyAsImportateur",this.defaultForm.importateur);
                      location.reload()
                      //ICI
                      //this.getSocietes();
                    } else {
                      this.showToast(result.data.message, "danger");
                    }
                  })
                  .catch((e) => {
                    this.isOperating = false;
                    var err = e.response.data.errors;
                    if (err.name) {
                      this.errorsName = err.name[0];
                    }
                    if (err.telephone) {
                      this.errorsTelephone = err.telephone[0];
                    }
                    if (err.email) {
                      this.errorsEmail = err.email[0];
                    }
                    if (err.ifu) {
                      this.errorsIfu = err.ifu[0];
                    }
                    if (err.rccm) {
                      this.errorsRccm = err.rccm[0];
                    }
                    if (err.responsable) {
                      this.errorsResponsable = err.responsable[0];
                    }
                    if (err.imgCarte) {
                      this.errorsCarte = err.imgCarte[0];
                    }
                  });
            }
          } else {
            this.$http
                .post(
                    "/societes",
                    (this.defaultForm.logo || this.defaultForm.imgCarte) ? formData : this.defaultForm
                )
                .then((result) => {
                  if (result.data.success) {
                    //this.resetInput();
                    /* this.defaultForm = JSON.parse(
                      JSON.stringify({ type: Object, default: () => {} })
                    ); */
                    this.showToast("Société ajouté avec succès", "success");
                    localStorage.setItem("societyAsImportateur",this.defaultForm.importateur);
                    location.reload()

                  } else {
                    this.showToast(result.data.message, "danger");
                  }
                  this.isOperating = false;
                })
                .catch((e) => {
                  this.isOperating = false;
                  var err = e.response.data.errors;
                  if (err.name) {
                    this.errorsName = err.name[0];
                  }
                  if (err.telephone) {
                    this.errorsTelephone = err.telephone[0];
                  }
                  if (err.email) {
                    this.errorsEmail = err.email[0];
                  }
                  if (err.ifu) {
                    this.errorsIfu = err.ifu[0];
                  }
                  if (err.rccm) {
                    this.errorsRccm = err.rccm[0];
                  }
                  if (err.responsable) {
                    this.errorsResponsable = err.responsable[0];
                  }
                  if (err.imgCarte) {
                    this.errorsCarte = err.imgCarte[0];
                  }
                });
          }
        }
      });
    },
  },
};
</script>
